
<script setup>

import { onMounted, ref, watch, computed } from 'vue'
import { useShare } from '@vueuse/core'
const { share, isSupported } = useShare()

import { videosAndOrganisations } from './firebase'
import { urlParams } from './helpers'


const { orgSlug, videoSlug } = urlParams()

const videos = ref([]),
      displayOrganisations = ref([]),
      organisations = ref([]),
      privateOrg = ref(null),
      filteredVideos = ref([]),
      currentVideoIndex = ref(0),
      videoRef = ref(null)

const filterVideos = org => {
  // if no org is provided, show all videos except private ones
  // otherwise, show all videos from that org
  filteredVideos.value = videos.value.filter(video => {
    if(org) return video.organisation.slug === org
    return !video.organisation.private
  })
}

const fetchInitialData = async () => {
  const response = await videosAndOrganisations()
  videos.value = response.data.videos
  organisations.value = response.data.organisations
  displayOrganisations.value = organisations.value.filter(org => !org.private)
}

const isPrivate = orgSlug => {
  return organisations?.value.find(org => org.slug === orgSlug)?.private
}

onMounted(async () => {
  await fetchInitialData()
  if (isPrivate(orgSlug)) {
    privateOrg.value = organisations.value.find(org => org.slug === orgSlug)
    displayOrganisations.value.unshift(privateOrg.value)
  }
  filterVideos()
  if(videoSlug) {
    const index = filteredVideos.value.findIndex(video => video.slug === videoSlug)
    if(index !== -1) currentVideoIndex.value = index
  }

  history.replaceState({}, '', currentVideoUrl.value)

  // window.atOptions = {
  //   'key' : 'd4f69d9a8ac0f6f2d1962be093148d77',
  //   'format' : 'iframe',
  //   'height' : 220,
  //   'width' : 375,
  //   'params' : {}
  // }

  // const adScript = document.createElement('script')
  // adScript.type = 'text/javascript'
  // adScript.src = '//www.topcreativeformat.com/d4f69d9a8ac0f6f2d1962be093148d77/invoke.js'
  // document.getElementById('ad-slot').appendChild(adScript)
})

const next = () => {
  currentVideoIndex.value = (currentVideoIndex.value + 1) % filteredVideos.value.length
  history.pushState({}, '', currentVideoUrl.value)
}

const previous = () => {
  currentVideoIndex.value = (currentVideoIndex.value - 1 + filteredVideos.value.length) % filteredVideos.value.length
  history.pushState({}, '', currentVideoUrl.value)
}


watch(currentVideoIndex, () => {
  if(videoRef.value) videoRef.value.load()
})

const currentVideoUrl = computed(() => {
  const org = filteredVideos?.value[currentVideoIndex.value]?.organisation.slug
  const slug = filteredVideos?.value[currentVideoIndex.value]?.slug
  return org && slug ? `${window.location.origin}/${org}/${slug}` : null
})

const changeOrg = e => {
  const org = e.target.value
  filterVideos(org)
  currentVideoIndex.value = 0
  videoRef.value.load()
  history.pushState({}, '', currentVideoUrl.value)
}

</script>


<template>
  <div class="page">
    <div class="social-container">
      <img class="logo" src="@/assets/GlobalInspiredBy-logo.jpeg" alt="Global InspiredBy logo" />
      <div class="gap"></div>
      <a href="https://facebook.com/globalinspiredby" target="_blank">
        <img
          class="social-icon"
          src="@/assets/facebook.svg"
          alt="Facebook"
        />
      </a>
      <a href="https://instagram.com/globalinspiredby" target="_blank">
        <img
          class="social-icon"
          src="@/assets/instagram.svg"
          alt="Instagram"
        />
      </a>
    </div>

    <div class="video-container loading" v-if="filteredVideos.length === 0">
      Loading...
    </div>

    <div v-else class="video-container">
      <video ref="videoRef" class="video" controls autoplay controlsList="nodownload noplaybackrate nofullscreen" oncontextmenu="return false" disablePictureInPicture>
        <source :src="filteredVideos[currentVideoIndex].video_url" type="video/mp4">
      </video>
    </div>

    <div class="controls-container">
      <select
        v-if="organisations.length && filteredVideos.length"
        @change="changeOrg"
      >
        <option
          v-for="organisation in displayOrganisations"
          :key="organisation.slug"
          :value="organisation.slug"
          :selected="organisation.slug === filteredVideos[currentVideoIndex].organisation.slug"
        >
          {{ organisation.name }}
        </option>
      </select>

      <select v-else disabled>
        <option>Loading...</option>
      </select>

      <div class="gap"></div>

      <div v-if="filteredVideos.length">
        <v-icon class="control-icon clickable" @click="previous" name="md-skipprevious-round" scale="1.1" />
        <v-icon class="control-icon clickable" @click="next" name="md-skipnext-round" scale="1.1" />

        <v-icon
          class="control-icon clickable"
          name="md-share"
          scale="1.1"
          v-if="isSupported"
          @click="share({
            title: 'Global InspiredBy',
            text: '¡Muchas gracias por ver este video!',
            url: currentVideoUrl
          })"
        />
      </div>
    </div>

    <div class="ad-container">
      <div class="text">Ads support creative communities</div>
      <!-- <div id="ad-slot"></div> -->
      <div class="ad"></div>
    </div>
  </div>
</template>


<style>
body {
  margin: 0; padding: 0;
  background-color: #ddd;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0;
  padding: 0;
}

div.page {
  display: flex;
  flex-direction: column;
  height: 100dvh;
  max-width: 500px;
  margin: 0 auto;
}

div.ad-container {
  height: calc(30dvh);
  flex-shrink: 0;
  padding: 10px;
  background: #eee;
}

div.social-container {
  display: flex;
  height: 44px;
  justify-content: space-between;
  width: 100%;
  background: white;
}

div.video-container {
  height: calc(70dvh - 120px);
  flex-shrink: 0;
  background: black;
  color: white;
}

div.controls-container {
  height: 48px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background: white;
}

div.ad {
  height: calc(100% - 20px);
  width: 100%;
  background-color: #8bc3e8;
  background-image: url('https://s3.eu-west-2.amazonaws.com/p.ublished/media2/2023/dec/77516/Certified%20Sustainable%20Hotels.jpg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 10px;
  margin-bottom: 10px;
}

div.ad-container div.text {
  font-size: 12px;
  margin-bottom: 5px;
  /* transform: rotate(-90deg) translate(-120%, 5px);
  transform-origin: 0 0; */
}


img.logo {
  max-width: 130px;  
  object-fit: contain;
  margin: 10px;
}

div.gap {
  flex-grow: 2;
}


div.social-icons {
  display: flex;
  flex-direction: row;
}

div.social-container a {
  text-decoration: none;
  display: flex;
  margin: 10px 10px 10px 5px;
  width: 24px;
}

div.social-container a img.social-icon {
  max-width: 24px;
  max-height: 24px;
}


div.video-container.loading {
  display: flex;
  justify-content: center;
  align-items: center;
}

video.video {
  object-fit: contain;
  width: 100%;
  height: 100%;
  background: black;
}
video.video::-webkit-media-controls-fullscreen-button {
  display: none;
}

select { 
  font-size: 15px;
  padding: 4px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.clickable {
  cursor: pointer;
}

.control-icon {
  margin: 0 10px;
}
</style>
